




































import WidgetMixins from '@/mixins/WidgetMixins';
import Component, { mixins } from 'vue-class-component';

@Component
export default class MigrateAccount extends mixins(WidgetMixins) {
  created(): void {
    console.log('Kljsfs');
  }
}
